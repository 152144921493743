// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-en-aumento-operatividad-js": () => import("./../../../src/pages/en/aumento_operatividad.js" /* webpackChunkName: "component---src-pages-en-aumento-operatividad-js" */),
  "component---src-pages-en-biblioteca-js": () => import("./../../../src/pages/en/biblioteca.js" /* webpackChunkName: "component---src-pages-en-biblioteca-js" */),
  "component---src-pages-en-blog-buen-sitio-ri-js": () => import("./../../../src/pages/en/blog/buen-sitio-ri.js" /* webpackChunkName: "component---src-pages-en-blog-buen-sitio-ri-js" */),
  "component---src-pages-en-blog-catalizadores-js": () => import("./../../../src/pages/en/blog/catalizadores.js" /* webpackChunkName: "component---src-pages-en-blog-catalizadores-js" */),
  "component---src-pages-en-blog-cuatro-pilares-exitosa-comunicacion-inversionistas-js": () => import("./../../../src/pages/en/blog/cuatro-pilares-exitosa-comunicacion-inversionistas.js" /* webpackChunkName: "component---src-pages-en-blog-cuatro-pilares-exitosa-comunicacion-inversionistas-js" */),
  "component---src-pages-en-blog-diversidad-juntas-js": () => import("./../../../src/pages/en/blog/diversidad-juntas.js" /* webpackChunkName: "component---src-pages-en-blog-diversidad-juntas-js" */),
  "component---src-pages-en-blog-dos-practicas-js": () => import("./../../../src/pages/en/blog/dos-practicas.js" /* webpackChunkName: "component---src-pages-en-blog-dos-practicas-js" */),
  "component---src-pages-en-blog-elecciones-eeuu-js": () => import("./../../../src/pages/en/blog/elecciones-eeuu.js" /* webpackChunkName: "component---src-pages-en-blog-elecciones-eeuu-js" */),
  "component---src-pages-en-blog-elementos-ir-micrositios-js": () => import("./../../../src/pages/en/blog/elementos-ir-micrositios.js" /* webpackChunkName: "component---src-pages-en-blog-elementos-ir-micrositios-js" */),
  "component---src-pages-en-blog-estructura-sitio-inversionistas-js": () => import("./../../../src/pages/en/blog/estructura-sitio-inversionistas.js" /* webpackChunkName: "component---src-pages-en-blog-estructura-sitio-inversionistas-js" */),
  "component---src-pages-en-blog-evolucion-relacion-js": () => import("./../../../src/pages/en/blog/evolucion-relacion.js" /* webpackChunkName: "component---src-pages-en-blog-evolucion-relacion-js" */),
  "component---src-pages-en-blog-fondos-cobertura-js": () => import("./../../../src/pages/en/blog/fondos-cobertura.js" /* webpackChunkName: "component---src-pages-en-blog-fondos-cobertura-js" */),
  "component---src-pages-en-blog-inversores-js": () => import("./../../../src/pages/en/blog/inversores.js" /* webpackChunkName: "component---src-pages-en-blog-inversores-js" */),
  "component---src-pages-en-blog-js": () => import("./../../../src/pages/en/blog.js" /* webpackChunkName: "component---src-pages-en-blog-js" */),
  "component---src-pages-en-blog-optimismo-js": () => import("./../../../src/pages/en/blog/optimismo.js" /* webpackChunkName: "component---src-pages-en-blog-optimismo-js" */),
  "component---src-pages-en-blog-perspectivas-2021-js": () => import("./../../../src/pages/en/blog/perspectivas-2021.js" /* webpackChunkName: "component---src-pages-en-blog-perspectivas-2021-js" */),
  "component---src-pages-en-blog-practicas-esg-js": () => import("./../../../src/pages/en/blog/practicas-esg.js" /* webpackChunkName: "component---src-pages-en-blog-practicas-esg-js" */),
  "component---src-pages-en-blog-presupuesto-ri-js": () => import("./../../../src/pages/en/blog/presupuesto-ri.js" /* webpackChunkName: "component---src-pages-en-blog-presupuesto-ri-js" */),
  "component---src-pages-en-blog-restauracion-confianza-js": () => import("./../../../src/pages/en/blog/restauracion-confianza.js" /* webpackChunkName: "component---src-pages-en-blog-restauracion-confianza-js" */),
  "component---src-pages-en-blog-tecnologiari-js": () => import("./../../../src/pages/en/blog/tecnologiari.js" /* webpackChunkName: "component---src-pages-en-blog-tecnologiari-js" */),
  "component---src-pages-en-blog-video-difusion-ir-js": () => import("./../../../src/pages/en/blog/video-difusion-ir.js" /* webpackChunkName: "component---src-pages-en-blog-video-difusion-ir-js" */),
  "component---src-pages-en-blog-vistazo-js": () => import("./../../../src/pages/en/blog/vistazo.js" /* webpackChunkName: "component---src-pages-en-blog-vistazo-js" */),
  "component---src-pages-en-clientes-js": () => import("./../../../src/pages/en/clientes.js" /* webpackChunkName: "component---src-pages-en-clientes-js" */),
  "component---src-pages-en-contacto-js": () => import("./../../../src/pages/en/contacto.js" /* webpackChunkName: "component---src-pages-en-contacto-js" */),
  "component---src-pages-en-emision-deuda-ce-js": () => import("./../../../src/pages/en/emision_deuda_ce.js" /* webpackChunkName: "component---src-pages-en-emision-deuda-ce-js" */),
  "component---src-pages-en-equipo-js": () => import("./../../../src/pages/en/equipo.js" /* webpackChunkName: "component---src-pages-en-equipo-js" */),
  "component---src-pages-en-fundamentales-js": () => import("./../../../src/pages/en/fundamentales.js" /* webpackChunkName: "component---src-pages-en-fundamentales-js" */),
  "component---src-pages-en-incursion-mercado-js": () => import("./../../../src/pages/en/incursion_mercado.js" /* webpackChunkName: "component---src-pages-en-incursion-mercado-js" */),
  "component---src-pages-en-js": () => import("./../../../src/pages/en.js" /* webpackChunkName: "component---src-pages-en-js" */),
  "component---src-pages-en-manejo-crisis-js": () => import("./../../../src/pages/en/manejo_crisis.js" /* webpackChunkName: "component---src-pages-en-manejo-crisis-js" */),
  "component---src-pages-en-medios-js": () => import("./../../../src/pages/en/medios.js" /* webpackChunkName: "component---src-pages-en-medios-js" */),
  "component---src-pages-en-perfil-js": () => import("./../../../src/pages/en/perfil.js" /* webpackChunkName: "component---src-pages-en-perfil-js" */),
  "component---src-pages-en-por-que-js": () => import("./../../../src/pages/en/por_que.js" /* webpackChunkName: "component---src-pages-en-por-que-js" */),
  "component---src-pages-en-posicionamiento-js": () => import("./../../../src/pages/en/posicionamiento.js" /* webpackChunkName: "component---src-pages-en-posicionamiento-js" */),
  "component---src-pages-en-programa-ipo-js": () => import("./../../../src/pages/en/programa_ipo.js" /* webpackChunkName: "component---src-pages-en-programa-ipo-js" */),
  "component---src-pages-en-programa-renta-js": () => import("./../../../src/pages/en/programa_renta.js" /* webpackChunkName: "component---src-pages-en-programa-renta-js" */),
  "component---src-pages-en-propuesta-js": () => import("./../../../src/pages/en/propuesta.js" /* webpackChunkName: "component---src-pages-en-propuesta-js" */),
  "component---src-pages-en-servicios-anual-sustentabilidad-js": () => import("./../../../src/pages/en/servicios/anual_sustentabilidad.js" /* webpackChunkName: "component---src-pages-en-servicios-anual-sustentabilidad-js" */),
  "component---src-pages-en-servicios-aumento-liquidez-js": () => import("./../../../src/pages/en/servicios/aumento_liquidez.js" /* webpackChunkName: "component---src-pages-en-servicios-aumento-liquidez-js" */),
  "component---src-pages-en-servicios-emision-deuda-js": () => import("./../../../src/pages/en/servicios/emision_deuda.js" /* webpackChunkName: "component---src-pages-en-servicios-emision-deuda-js" */),
  "component---src-pages-en-servicios-irstrat-1-js": () => import("./../../../src/pages/en/servicios/irstrat_1.js" /* webpackChunkName: "component---src-pages-en-servicios-irstrat-1-js" */),
  "component---src-pages-en-servicios-irstrat-2-js": () => import("./../../../src/pages/en/servicios/irstrat_2.js" /* webpackChunkName: "component---src-pages-en-servicios-irstrat-2-js" */),
  "component---src-pages-en-servicios-irstrat-3-js": () => import("./../../../src/pages/en/servicios/irstrat_3.js" /* webpackChunkName: "component---src-pages-en-servicios-irstrat-3-js" */),
  "component---src-pages-en-servicios-js": () => import("./../../../src/pages/en/servicios.js" /* webpackChunkName: "component---src-pages-en-servicios-js" */),
  "component---src-pages-en-servicios-programa-incursion-js": () => import("./../../../src/pages/en/servicios/programa_incursion.js" /* webpackChunkName: "component---src-pages-en-servicios-programa-incursion-js" */),
  "component---src-pages-en-servicios-programa-ipo-medida-js": () => import("./../../../src/pages/en/servicios/programa_ipo_medida.js" /* webpackChunkName: "component---src-pages-en-servicios-programa-ipo-medida-js" */),
  "component---src-pages-en-servicios-programa-medida-renta-js": () => import("./../../../src/pages/en/servicios/programa_medida_renta.js" /* webpackChunkName: "component---src-pages-en-servicios-programa-medida-renta-js" */),
  "component---src-pages-es-aumento-operatividad-js": () => import("./../../../src/pages/es/aumento_operatividad.js" /* webpackChunkName: "component---src-pages-es-aumento-operatividad-js" */),
  "component---src-pages-es-biblioteca-js": () => import("./../../../src/pages/es/biblioteca.js" /* webpackChunkName: "component---src-pages-es-biblioteca-js" */),
  "component---src-pages-es-blog-buen-sitio-ri-js": () => import("./../../../src/pages/es/blog/buen-sitio-ri.js" /* webpackChunkName: "component---src-pages-es-blog-buen-sitio-ri-js" */),
  "component---src-pages-es-blog-catalizadores-js": () => import("./../../../src/pages/es/blog/catalizadores.js" /* webpackChunkName: "component---src-pages-es-blog-catalizadores-js" */),
  "component---src-pages-es-blog-cuatro-pilares-exitosa-comunicacion-inversionistas-js": () => import("./../../../src/pages/es/blog/cuatro-pilares-exitosa-comunicacion-inversionistas.js" /* webpackChunkName: "component---src-pages-es-blog-cuatro-pilares-exitosa-comunicacion-inversionistas-js" */),
  "component---src-pages-es-blog-diversidad-juntas-js": () => import("./../../../src/pages/es/blog/diversidad-juntas.js" /* webpackChunkName: "component---src-pages-es-blog-diversidad-juntas-js" */),
  "component---src-pages-es-blog-dos-practicas-js": () => import("./../../../src/pages/es/blog/dos-practicas.js" /* webpackChunkName: "component---src-pages-es-blog-dos-practicas-js" */),
  "component---src-pages-es-blog-elecciones-eeuu-js": () => import("./../../../src/pages/es/blog/elecciones-eeuu.js" /* webpackChunkName: "component---src-pages-es-blog-elecciones-eeuu-js" */),
  "component---src-pages-es-blog-elementos-ir-micrositios-js": () => import("./../../../src/pages/es/blog/elementos-ir-micrositios.js" /* webpackChunkName: "component---src-pages-es-blog-elementos-ir-micrositios-js" */),
  "component---src-pages-es-blog-estructura-sitio-inversionistas-js": () => import("./../../../src/pages/es/blog/estructura-sitio-inversionistas.js" /* webpackChunkName: "component---src-pages-es-blog-estructura-sitio-inversionistas-js" */),
  "component---src-pages-es-blog-evolucion-relacion-js": () => import("./../../../src/pages/es/blog/evolucion-relacion.js" /* webpackChunkName: "component---src-pages-es-blog-evolucion-relacion-js" */),
  "component---src-pages-es-blog-fondos-cobertura-js": () => import("./../../../src/pages/es/blog/fondos-cobertura.js" /* webpackChunkName: "component---src-pages-es-blog-fondos-cobertura-js" */),
  "component---src-pages-es-blog-inversores-js": () => import("./../../../src/pages/es/blog/inversores.js" /* webpackChunkName: "component---src-pages-es-blog-inversores-js" */),
  "component---src-pages-es-blog-js": () => import("./../../../src/pages/es/blog.js" /* webpackChunkName: "component---src-pages-es-blog-js" */),
  "component---src-pages-es-blog-optimismo-js": () => import("./../../../src/pages/es/blog/optimismo.js" /* webpackChunkName: "component---src-pages-es-blog-optimismo-js" */),
  "component---src-pages-es-blog-perspectivas-2021-js": () => import("./../../../src/pages/es/blog/perspectivas-2021.js" /* webpackChunkName: "component---src-pages-es-blog-perspectivas-2021-js" */),
  "component---src-pages-es-blog-practicas-esg-js": () => import("./../../../src/pages/es/blog/practicas-esg.js" /* webpackChunkName: "component---src-pages-es-blog-practicas-esg-js" */),
  "component---src-pages-es-blog-presupuesto-ri-js": () => import("./../../../src/pages/es/blog/presupuesto-ri.js" /* webpackChunkName: "component---src-pages-es-blog-presupuesto-ri-js" */),
  "component---src-pages-es-blog-restauracion-confianza-js": () => import("./../../../src/pages/es/blog/restauracion-confianza.js" /* webpackChunkName: "component---src-pages-es-blog-restauracion-confianza-js" */),
  "component---src-pages-es-blog-tecnologiari-js": () => import("./../../../src/pages/es/blog/tecnologiari.js" /* webpackChunkName: "component---src-pages-es-blog-tecnologiari-js" */),
  "component---src-pages-es-blog-video-difusion-ir-js": () => import("./../../../src/pages/es/blog/video-difusion-ir.js" /* webpackChunkName: "component---src-pages-es-blog-video-difusion-ir-js" */),
  "component---src-pages-es-blog-vistazo-js": () => import("./../../../src/pages/es/blog/vistazo.js" /* webpackChunkName: "component---src-pages-es-blog-vistazo-js" */),
  "component---src-pages-es-clientes-js": () => import("./../../../src/pages/es/clientes.js" /* webpackChunkName: "component---src-pages-es-clientes-js" */),
  "component---src-pages-es-contacto-js": () => import("./../../../src/pages/es/contacto.js" /* webpackChunkName: "component---src-pages-es-contacto-js" */),
  "component---src-pages-es-emision-deuda-ce-js": () => import("./../../../src/pages/es/emision_deuda_ce.js" /* webpackChunkName: "component---src-pages-es-emision-deuda-ce-js" */),
  "component---src-pages-es-equipo-js": () => import("./../../../src/pages/es/equipo.js" /* webpackChunkName: "component---src-pages-es-equipo-js" */),
  "component---src-pages-es-fundamentales-js": () => import("./../../../src/pages/es/fundamentales.js" /* webpackChunkName: "component---src-pages-es-fundamentales-js" */),
  "component---src-pages-es-incursion-mercado-js": () => import("./../../../src/pages/es/incursion_mercado.js" /* webpackChunkName: "component---src-pages-es-incursion-mercado-js" */),
  "component---src-pages-es-manejo-crisis-js": () => import("./../../../src/pages/es/manejo_crisis.js" /* webpackChunkName: "component---src-pages-es-manejo-crisis-js" */),
  "component---src-pages-es-medios-js": () => import("./../../../src/pages/es/medios.js" /* webpackChunkName: "component---src-pages-es-medios-js" */),
  "component---src-pages-es-perfil-js": () => import("./../../../src/pages/es/perfil.js" /* webpackChunkName: "component---src-pages-es-perfil-js" */),
  "component---src-pages-es-por-que-js": () => import("./../../../src/pages/es/por_que.js" /* webpackChunkName: "component---src-pages-es-por-que-js" */),
  "component---src-pages-es-posicionamiento-js": () => import("./../../../src/pages/es/posicionamiento.js" /* webpackChunkName: "component---src-pages-es-posicionamiento-js" */),
  "component---src-pages-es-programa-ipo-js": () => import("./../../../src/pages/es/programa_ipo.js" /* webpackChunkName: "component---src-pages-es-programa-ipo-js" */),
  "component---src-pages-es-programa-renta-js": () => import("./../../../src/pages/es/programa_renta.js" /* webpackChunkName: "component---src-pages-es-programa-renta-js" */),
  "component---src-pages-es-propuesta-js": () => import("./../../../src/pages/es/propuesta.js" /* webpackChunkName: "component---src-pages-es-propuesta-js" */),
  "component---src-pages-es-servicios-anual-sustentabilidad-js": () => import("./../../../src/pages/es/servicios/anual_sustentabilidad.js" /* webpackChunkName: "component---src-pages-es-servicios-anual-sustentabilidad-js" */),
  "component---src-pages-es-servicios-aumento-liquidez-js": () => import("./../../../src/pages/es/servicios/aumento_liquidez.js" /* webpackChunkName: "component---src-pages-es-servicios-aumento-liquidez-js" */),
  "component---src-pages-es-servicios-emision-deuda-js": () => import("./../../../src/pages/es/servicios/emision_deuda.js" /* webpackChunkName: "component---src-pages-es-servicios-emision-deuda-js" */),
  "component---src-pages-es-servicios-irstrat-1-js": () => import("./../../../src/pages/es/servicios/irstrat_1.js" /* webpackChunkName: "component---src-pages-es-servicios-irstrat-1-js" */),
  "component---src-pages-es-servicios-irstrat-2-js": () => import("./../../../src/pages/es/servicios/irstrat_2.js" /* webpackChunkName: "component---src-pages-es-servicios-irstrat-2-js" */),
  "component---src-pages-es-servicios-irstrat-3-js": () => import("./../../../src/pages/es/servicios/irstrat_3.js" /* webpackChunkName: "component---src-pages-es-servicios-irstrat-3-js" */),
  "component---src-pages-es-servicios-js": () => import("./../../../src/pages/es/servicios.js" /* webpackChunkName: "component---src-pages-es-servicios-js" */),
  "component---src-pages-es-servicios-programa-incursion-js": () => import("./../../../src/pages/es/servicios/programa_incursion.js" /* webpackChunkName: "component---src-pages-es-servicios-programa-incursion-js" */),
  "component---src-pages-es-servicios-programa-ipo-medida-js": () => import("./../../../src/pages/es/servicios/programa_ipo_medida.js" /* webpackChunkName: "component---src-pages-es-servicios-programa-ipo-medida-js" */),
  "component---src-pages-es-servicios-programa-medida-renta-js": () => import("./../../../src/pages/es/servicios/programa_medida_renta.js" /* webpackChunkName: "component---src-pages-es-servicios-programa-medida-renta-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-single-js": () => import("./../../../src/pages/single.js" /* webpackChunkName: "component---src-pages-single-js" */)
}

